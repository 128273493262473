<template>
  <div id="home-mobile-header-popper-container" :class="$style.wrapper">
    <div :class="$style.headerWrapper">
      <div
        :class="[$style.fixedWrapper, { [$style.withShadow]: opacity === 1 }]"
        :style="`background: rgba(255, 255, 255, ${opacity})`"
      >
        <Link
          :class="$style.link"
          :child-class="$style.link"
          :link="SHOWCASE_ROUTE_PROFILE_BUILDER"
        >
          <PromoBar appearance="secondary">
            <LoadingText :is-loading="!registrationDiscount">
              <RichTranslation
                translation="⚡ Offre d'essai : <bold>{discount}</bold> sur la premiere commande ⚡"
                :values="{
                  discount:
                    registrationDiscount &&
                    formatDiscountValue(registrationDiscount),
                }"
              >
                <template #bold="value">
                  <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
                    {{ value }}
                  </Typography>
                </template>
              </RichTranslation>
            </LoadingText>
          </PromoBar>
        </Link>

        <Container>
          <Header />
        </Container>
      </div>
    </div>

    <Hero :class="$style.hero" />

    <Press />

    <Container>
      <Simulator :class="$style.simulator" />
    </Container>

    <Container>
      <Ingredients :class="$style.ingredients" />
    </Container>

    <Manufacture :class="$style.manufacture" />

    <HowItWorks />

    <TwicVideo
      :class="$style.video"
      ratio="none"
      focus="auto"
      intrinsic="1920x1080"
      alt="Vidéo d'un client recevant un paquet de croquettes Japhy, l'ouvrant, puis servant les croquettes à son chien qui mange avec enthousiasme"
      src="https://japhy-assets.twic.pics/video/uc?id=1BYqkkIgPcUECYjaSA4vfr0GO9_5q0_JP&export=download"
    />

    <MemberArea />

    <Testimonials />

    <Products :class="$style.products" />

    <Container>
      <Social :class="$style.social" />
    </Container>

    <Container>
      <FAQ />
    </Container>

    <Footer />

    <EnvironmentVersion />

    <Alert />
  </div>
</template>

<script>
import { LoadingText } from '@front/common/components'
import useScroll from '@front/common/hooks/useScroll'
import { RichTranslation } from '@front/common/i18n'
import {
  formatDiscountValue,
  getCookie,
  normalize,
  setCookie,
} from '@front/common/utils'
import {
  Press,
  Simulator,
  Ingredients,
  Products,
  FAQ,
  Social,
  Footer,
  Manufacture,
  HowItWorks,
  MemberArea,
  Testimonials,
} from '@front/custom-elements'
import {
  Alert,
  EnvironmentVersion,
  Typography,
  TYPOGRAPHY_TYPES,
} from '@front/ui'
import { computed, defineComponent, onBeforeMount } from '@vue/composition-api'
import { useVuex } from '@vueblocks/vue-use-vuex'

import Container from '@showcase/components/Container.vue'
import Header from '@showcase/components/Header'
import Link from '@showcase/components/Link.vue'
import PromoBar from '@showcase/components/PromoBar.vue'
import { SHOWCASE_ROUTE_PROFILE_BUILDER } from '@showcase/core/routes-names'

import Hero from './components/Hero.vue'

export default defineComponent({
  components: {
    Header,
    Container,
    Hero,
    Press,
    Simulator,
    Ingredients,
    Products,
    FAQ,
    Social,
    Footer,
    Alert,
    Manufacture,
    HowItWorks,
    MemberArea,
    Testimonials,
    EnvironmentVersion,
    PromoBar,
    Link,
    RichTranslation,
    Typography,
    LoadingText,
  },
  setup(props, { root }) {
    const { scrollY } = useScroll()
    const { useGetters } = useVuex()

    const opacity = computed(() => {
      return Math.min(1, normalize(scrollY.value, [0, 200], [0, 1]))
    })

    const { discounts } = useGetters({
      discounts: 'discounts/get',
    })

    const registrationDiscount = computed(() => {
      return discounts.value?.registration
    })

    onBeforeMount(() => {
      if (getCookie('utm_landing')) {
        return
      }

      if (root.$route.query.utm_landing) {
        setCookie('utm_landing', root.$route.query.utm_landing, {
          daysToLive: 20,
        })
        return
      }

      setCookie('utm_landing', document.location.href, { daysToLive: 20 })
    })

    return {
      opacity,
      SHOWCASE_ROUTE_PROFILE_BUILDER,
      TYPOGRAPHY_TYPES,
      registrationDiscount,
      formatDiscountValue,
    }
  },
  head() {
    return {
      title: 'Japhy - Alimentation saine et naturelle pour chiens et chats',
      meta: [
        { charset: 'utf-8' },
        {
          name: 'description',
          content:
            'Japhy propose des croquettes personnalisées pour votre animal, fabriquées en France avec amour et adaptées à ses besoins. Surprises dans chaque colis.',
        },
        {
          property: 'og:title',
          content:
            'Japhy - Alimentation saine et naturelle pour chiens et chats',
        },
        {
          property: 'og:description',
          content:
            'Japhy propose des croquettes personnalisées pour votre animal, fabriquées en France avec amour et adaptées à ses besoins. Surprises dans chaque colis.',
        },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://www.japhy.fr' },
        {
          property: 'og:image',
          content:
            'https://cdn.prod.website-files.com/620a7aa4b1afe6627785709b/6231fe3b3702d9a42a340030_favicon-0.png',
        },
        { property: 'og:url', content: 'https://www.japhy.fr' },
        {
          name: 'twitter:title',
          content:
            'Japhy - Alimentation saine et naturelle pour chiens et chats',
        },
        {
          name: 'twitter:description',
          content:
            'Japhy propose des croquettes personnalisées pour votre animal, fabriquées en France avec amour et adaptées à ses besoins. Surprises dans chaque colis.',
        },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
      link: [
        { rel: 'canonical', href: 'https://www.japhy.fr' },
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: 'https://cdn.prod.website-files.com/620a7aa4b1afe6627785709b/6231fe3b3702d9a42a340030_favicon-0.png',
        },
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Japhy',
            url: 'https://www.japhy.fr',
            logo: 'https://cdn.prod.website-files.com/620a7aa4b1afe6627785709b/6231fe3b3702d9a42a340030_favicon-0.png',
            description:
              'Japhy propose des croquettes personnalisées et saines pour chiens, fabriquées en France avec amour.',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '0970254404',
              contactType: 'Service Client',
              areaServed: 'FR',
              availableLanguage: ['Français'],
            },
            sameAs: [
              'https://www.facebook.com/japhy',
              'https://www.instagram.com/japhy.fr',
            ],
          },
        },
        {
          src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
          defer: true,
          async: true,
        },
      ],
    }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
}

.simulator {
  margin-top: $ds-spacing-xl;
}

.ingredients {
  margin-top: $ds-spacing-xl;
}

.social {
  margin-top: $ds-spacing-xl;
}

.manufacture {
  margin-top: $ds-spacing-xl;
}

.products {
  margin-top: $ds-spacing-xl;
}

.video {
  margin-top: $ds-spacing-xl;
  width: 100%;
  height: 411px;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    height: 695px;
  }
}

.headerWrapper {
  height: 117px;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    height: 88px;
  }
}

.fixedWrapper {
  display: flex;
  flex-direction: column;
  background: white;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 15000;

  &.withShadow {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  }

  &.isViewportOnTop {
    background: transparent;
  }
}

.link {
  width: 100%;
  color: $ds-color-black;
}
</style>
